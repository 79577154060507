<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="col-12 text-primary py-2">
                    All Withdrawals
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Ref</th>
                            <th scope="col">User</th>
                            <th scope="col">Status</th>
                            <th scope="col">Address</th>
                            <th scope="col">Crypto Amount</th>
                            <th scope="col">Fiat Amount</th>
                            <th scope="col">Date created</th>
                            <th scope="col" class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="getAllSentPayments.length > 0">
                            <tr v-for="(payment, idx) in getAllSentPayments" :key="idx">
                                <th scope="row">{{idx+1}}</th>
                                <td>{{payment.id}}</td>
                                <td>
                                    <span v-if="users[idx]!==undefined">{{users[idx].data.name}}</span>
                                    <span v-else>{{payment.data.user}}</span>
                                </td>
                                <td>
                                    <span class="badge badge-success" v-if="payment.data.status === 'CONFIRMED'">
                                        {{payment.data.status}}
                                    </span>
                                    <span class="badge badge-danger" v-else-if="payment.data.status === 'FAILED'">
                                        {{payment.data.status}}
                                    </span>
                                    <span class="badge badge-warning" v-else-if="payment.data.status === 'PENDING'">
                                        {{payment.data.status}}
                                    </span>
                                    <span class="badge badge-primary" v-else>
                                        {{payment.data.status}}
                                    </span>
                                </td>
                                <td>{{payment.data.address}}</td>
                                <td>{{payment.data.crypto_value}} {{payment.data.crypto}}</td>
                                <td>{{payment.data.currency_value}} {{payment.data.currency}}</td>
                                <td>{{getReadableDatetime(payment.data.created_at)}}</td>
                                <td>
                                    <div class="row justify-content-around">
                                        <!--                                        <ion-icon @click="active_payload=payment" data-bs-toggle="modal" data-bs-target="#viewDepositInfo" class="text-primary fw-20" name="eye-outline"></ion-icon>-->
                                        <ion-icon v-if="payment.data.status!=='CONFIRMED'" @click="complete_transaction(payment.id)" name="checkmark-done-outline" class="text-warning fw-20"></ion-icon>
                                        <ion-icon class="text-primary fw-20" name="ban-outline" v-else></ion-icon>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center text-primary" colspan="8">
                                    <h5>Empty withdrawal history</h5>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "withdrawal",
    data(){
        return {
            users: [],
            users_fetch_interval: -1
        }
    },
    computed: {
        ...mapGetters('transaction', [
            'getAllSentPayments'
        ])
    },
    mixins: [methodsMixin],
    methods: {
        complete_transaction(paymentId) {
            this.$store.dispatch('notification/display_noti', {
                message: 'Confirm this payment? <br> This operation can not be reversed!',
                context: 'icon',
                buttons: [
                    {
                        name: 'Proceed',
                        callback: async () => {
                            this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                            let response = await this.$store.dispatch('transaction/confirmWithdrawal', paymentId);
                            if (response.status) {
                                return this.$store.dispatch('notification/display_noti', {
                                    message: 'Withdrawal confirmed!',
                                    timeout: 1500
                                });
                            } else {
                                return this.$store.dispatch('notification/display_noti', {
                                    message: response.message,
                                });
                            }
                        }
                    },
                    {
                        name: 'Cancel',
                        callback: () => this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                    }
                ]
            });
        },
        async fetchUsers() {
            if (this.getAllSentPayments.length > 0) {
                clearInterval(this.users_fetch_interval);
                try {
                    let promises = this.getAllSentPayments.map(payment => this.$store.dispatch('user/getUser', payment.data.user));
                    let responses = await Promise.all(promises);
                    this.users = responses.map(response => response.data);
                } catch (e) {
                    console.log(e)
                }
            } else {
                this.users_fetch_interval = setInterval(this.fetchUsers, 2000);
            }
        },
    },
    mounted() {
        this.$store.dispatch('transaction/fetchAllSentPayments');
        this.fetchUsers()
    }
}
</script>

<style scoped>

</style>